import { css } from "styled-components";

export const fontUrl = (font) => `https://cdn.nested.com/fonts/${font}`;

export const fonts = css`
  @font-face {
    font-display: swap;
    font-family: "StriverText";
    src: url("${fontUrl("StriverText-Bold.woff2")}") format("woff2"),
      url("${fontUrl("StriverText-Bold.woff")}") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: Euclid Circular B;
    src: url("${fontUrl("EuclidCircularB-Light-WebS.woff2")}") format("woff2"),
      url("${fontUrl("EuclidCircularB-Light-WebS.woff")}") format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: Euclid Circular B;
    src: url("${fontUrl("EuclidCircularB-Light-WebS.woff2")}") format("woff2"),
      url("${fontUrl("EuclidCircularB-Light-WebS.woff")}") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: Euclid Circular B;
    src: url("${fontUrl("EuclidCircularB-Medium-WebS.woff2")}") format("woff2"),
      url("${fontUrl("EuclidCircularB-Medium-WebS.woff")}") format("woff");
    font-weight: 500;
    font-style: normal;
  }
`;
