// @flow
import { useContext, useEffect, useRef, useState } from "react";
import { css, ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getConfig } from "@nested/config";
import { ButtonLink } from "@nested/component-library";
import { media } from "@nested/brand";
import { NestedLogo } from "@nested/brand/src/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBinoculars,
  faChartPie,
  faCity,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

const { ACCOUNT_LOGIN_URI } = getConfig();

const backgroundStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: ${({ open }) =>
    open ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"};
  z-index: 101;
  pointer-events: ${({ open }) => (open ? "unset" : "none")};
  transition: background-color 200ms ease-out;
`;

const closeButtonStyle = css`
  cursor: pointer;
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 25px;
  right: 25px;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: none;

  div:first-child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.hague40};
    transform: rotate(45deg);
  }

  div:last-child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.hague40};
    transform: rotate(-45deg);
  }
`;

const navStyle = css`
  height: 55px;
  box-sizing: border-box;
  padding: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  ${media.tablet`
    height: 80px;
    padding: 15px 10px;
  `};
`;

const logoWrapper = css`
  flex-grow: 1;
`;

const logoStyle = css`
  margin-left: 10px;
  height: 29px;
  ${media.tablet`
    margin-left: 0px;
    width: 130.7px;
    height: 32px;
  `}
`;

const menuButtonStyle = css`
  width: 35px;
  height: 35px;
  background-color: ${({ theme }) => theme.palette.sand100};
  border: none;
  outline: none;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  padding: 12px 10px 10px 10px;
  box-sizing: border-box;
  margin-right: 10px;
  -webkit-tap-highlight-color: transparent;
  ${media.tablet`
    width: 40px;
    height: 40px;
    padding: 14px 12px 12px 12px;
  `};

  ${media.desktop`
    display: none;
  `};
`;

const burgerLineStyle = css`
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.palette.hague};
  margin-bottom: 3px;

  ${media.tablet`
    margin-bottom: 4px;
  `};
`;

const menuStyle = css`
  position: absolute;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  right: ${({ open }) => (open ? "0px" : "-250px")};
  width: 250px;
  will-change: right;
  background-color: white;
  visibility: ${({ open }) => (open ? "visbile" : "hidden")};
  transition: right 200ms ease-out, visibility 200ms ease-out;
`;

const linksListStyle = css`
  margin-top: 75px;
  padding: 0;
  list-style-type: none;
`;

const linksListItemStyle = css`
  margin-top: 25px;
  padding-left: 25px;
`;

const menuListIconStyle = css`
  display: inline-block;
  margin-right: 20px;
`;

const linkStyle = css`
  text-decoration: none;
`;

const buttonStyles = css`
  width: 100%;
  text-align: center;

  &:first-of-type {
    margin-bottom: 10px;
  }
`;

const buttonWrapperStyles = css`
  width: calc(100% - 25px);
  padding: 0 25px;
  position: absolute;
  box-sizing: border-box;
  bottom: 30px;
  left: 0;
  margin: auto;
`;

const topNavCtaStyle = css`
  display: inline-block;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  vertical-align: top;

  ${media.tablet`
    height: 40px;
    line-height: 40px;
  `}
`;

const topNavLoginStyle = css`
  display: none;
  ${media.desktop`
    display: inline-block;
    margin-right: 10px;
  `};
`;

const topNavMenuList = css`
  list-style-type: none;
  padding: 0;
  ${media.desktop`
    display: inline-flex;
    max-width: 650px;
    vertical-align: top;
    height: 40px;
    flex-grow: 1;
    justify-content: flex-end;
    text-align: right;
  `};
`;

const topNavMenuListItem = css`
  display: none;
  ${media.desktop`
  flex-grow: 1;
  position: relative;
  display: inline-block;
  top: 2px;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    margin-right: 20px;
  }
  `};
`;

const topNavMenuButtons = css`
  flex-grow: 1;
  text-align: right;
`;

const MenuItem = ({ to, children, icon, onClose }) => {
  const theme = useContext(ThemeContext);
  return (
    <li css={linksListItemStyle}>
      <Link css={linkStyle} to={to} onClick={onClose}>
        <span css={menuListIconStyle}>
          <FontAwesomeIcon color={theme.palette.terracotta100} icon={icon} />
        </span>
        {children}
      </Link>
    </li>
  );
};

const Menu = ({ menuRef, open, onClose }) => (
  // eslint-disable-next-line
  <div onClick={onClose} open={open} css={backgroundStyle}>
    {/* eslint-disable-next-line */}
    <div onClick={(e) => e.stopPropagation()} open={open} css={menuStyle}>
      <button type="button" onClick={onClose} css={closeButtonStyle}>
        <div />
        <div />
      </button>
      <ul ref={menuRef} css={linksListStyle}>
        <MenuItem to="/selling" icon={faChartPie} onClose={onClose}>
          Selling
        </MenuItem>
        <MenuItem to="/lettings" icon={faCity} onClose={onClose}>
          Letting
        </MenuItem>
        <MenuItem to="/for-sale" icon={faBinoculars} onClose={onClose}>
          Buying
        </MenuItem>
        <MenuItem to="/partnerships" icon={faHandshake} onClose={onClose}>
          Become an Agent
        </MenuItem>
      </ul>
      <div css={buttonWrapperStyles}>
        <ButtonLink type="accent" css={buttonStyles} to="/get-started">
          Speak with us
        </ButtonLink>
        <ButtonLink css={buttonStyles} to={ACCOUNT_LOGIN_URI}>
          Login
        </ButtonLink>
      </div>
    </div>
  </div>
);

type Props = {
  initialMenuOpen?: boolean,
};

export const Navigation = ({ initialMenuOpen = false }: Props) => {
  const [menuOpen, setMenuOpen] = useState(initialMenuOpen);
  const menuRef = useRef();
  useEffect(() => {
    if (menuOpen) {
      disableBodyScroll(menuRef.current);
    } else {
      enableBodyScroll(menuRef.current);
    }

    return () => {
      enableBodyScroll(menuRef.current);
    };
  }, [menuOpen]);

  return (
    <nav css={navStyle}>
      <Menu
        menuRef={menuRef}
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
      />
      <div css={logoWrapper}>
        <Link to="/">
          <NestedLogo css={logoStyle} />
        </Link>
      </div>
      <ul css={topNavMenuList}>
        <li css={topNavMenuListItem}>
          <Link to="/selling">Selling</Link>
        </li>
        <li css={topNavMenuListItem}>
          <Link to="/lettings">Letting</Link>
        </li>
        <li css={topNavMenuListItem}>
          <Link to="/for-sale">Buying</Link>
        </li>
        <li css={topNavMenuListItem}>
          <Link to="/partnerships">Become an agent</Link>
        </li>
        <li css={topNavMenuButtons}>
          <ButtonLink type="accent" css={topNavCtaStyle} to="/get-started">
            Speak with us
          </ButtonLink>
          <ButtonLink css={topNavLoginStyle} to={ACCOUNT_LOGIN_URI}>
            Login
          </ButtonLink>
          <button
            type="button"
            onClick={() => setMenuOpen(true)}
            css={menuButtonStyle}
          >
            <span css={burgerLineStyle} />
            <span css={burgerLineStyle} />
            <span css={burgerLineStyle} />
          </button>
        </li>
      </ul>
    </nav>
  );
};
