import { Helmet } from "react-helmet";
import { fontUrl } from "@nested/brand";
import { getImage } from "@nested/utils";

export const MetaTags = ({ children = null, disableZoom = false }) => (
  <Helmet>
    <meta charSet="utf-8" />
    {disableZoom ? (
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
    ) : (
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    )}
    <link
      rel="preload"
      href={fontUrl("EuclidCircularB-Light-WebS.woff2")}
      as="font"
      crossOrigin
    />
    <link
      rel="preload"
      href={fontUrl("EuclidCircularB-Medium-WebS.woff2")}
      as="font"
      crossOrigin
    />
    <link
      rel="preload"
      href={fontUrl("StriverText-Bold.woff2")}
      as="font"
      crossOrigin
    />
    <link
      href="https://fonts.googleapis.com/css?family=Reenie+Beanie&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://use.fontawesome.com/releases/v5.11.2/css/svg-with-js.css"
      rel="stylesheet"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="48x48"
      href={getImage("icons/rebrand-favicon-48.png")}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={getImage("icons/rebrand-favicon-32.png")}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={getImage("icons/rebrand-favicon-16.png")}
    />
    {children}
  </Helmet>
);
