// @flow
import { css, ThemeProvider } from "styled-components";
import { renderRoutes } from "react-router-config";
import { theme, GlobalStyles, MetaTags, Navigation } from "@nested/brand";
import { ProgressBar } from "./ProgressBar";

const backgroundStyle = css`
  width: 100%;
  min-height: 100vh;
  background-color: ${theme.palette.sand40};
  position: relative;
`;

const wrapperStyle = css`
  width: 100%;
  margin: 0px auto;
  max-width: 1440px;
  background-color: white;
  overflow-x: hidden;
`;

type Props = RouteProps<{}>;

export const UniversalRender = ({ route: { routes } }: Props) => (
  <ThemeProvider theme={theme}>
    <MetaTags />
    <GlobalStyles />
    <div css={backgroundStyle}>
      <div css={wrapperStyle}>
        <ProgressBar />
        <Navigation />
        {renderRoutes(routes)}
      </div>
    </div>
  </ThemeProvider>
);
