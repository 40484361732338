export const NestedLogo = ({ fill = "#094254", ...props }) => (
  <svg {...props} viewBox="0 0 5718 1616">
    <path
      d="M1386.79,481.66 L778.73,18.17 C747.98404,-5.28990767 705.34596,-5.28990767 674.6,18.17 L67.69,481.65 C25.1647683,514.130195 0.21,564.579642 0.21,618.09 L0.21,1532.92 C0.21,1578.26258 36.967422,1615.02 82.31,1615.02 L1372.31,1615.02 C1417.65258,1615.02 1454.41,1578.26258 1454.41,1532.92 L1454.41,618.18 C1454.41,564.615353 1429.39638,514.123328 1386.79,481.66 L1386.79,481.66 Z M1247.03,1371.66 C1247.03,1392.27127 1230.32127,1408.98 1209.71,1408.98 L1013.65,1408.98 C993.038733,1408.98 976.33,1392.27127 976.33,1371.66 L976.33,887.94 C976.33,748.94 862.75,636.48 723.33,638.29 C586.13,640.07 477,754.44 477,891.66 L477,1371.66 C477.005312,1381.56654 473.071621,1391.06872 466.065697,1398.07277 C459.059774,1405.07682 449.556543,1409.00001 439.65,1409.00001 L243.59,1409.00001 C233.692121,1409.00001 224.199633,1405.06808 217.200775,1398.06923 C210.201917,1391.07037 206.27,1381.57788 206.27,1371.68 L206.27,892.92 C206.27,604.82 438.27,367.71 726.38,367.569928 C1013.9,367.42 1247.03,600.46 1247.03,887.94 L1247.03,1371.66 Z"
      id="Shape"
      fill="#FF8871"
    />
    <path
      d="M3589.46,1289.85 C3706.38,1289.85 3830.65,1220.38 3830.65,1091.65 C3830.65,964.25 3711.36,916.43 3594.65,886.46 C3523.65,868.35 3477.16,853.17 3477.16,808.88 C3477.16,751.74 3538.96,725.7 3596.37,725.7 C3641.47,725.7 3685.84,740.84 3728.2,770.7 C3728.82514,771.217012 3729.63302,771.45881 3730.43941,771.370258 C3731.2458,771.281706 3731.98195,770.870353 3732.48,770.23 L3792.97,686.96 C3795.50722,683.519754 3794.85664,678.686842 3791.5,676.04 C3735.02,634.51 3666.57,613.46 3588.05,613.46 C3472.56,613.46 3348.26,676.79 3348.26,815.83 C3348.26,892.25 3399.7,949.83 3497.01,982.22 C3519.68,989.78 3543.01,995.76 3565.54,1001.53 C3635.8,1019.53 3701.73,1036.38 3701.73,1094.42 C3701.73,1146.49 3643.23,1177.61 3586.67,1177.61 C3524.67,1177.61 3460.92,1154.94 3402.22,1112.05 C3401.64172,1111.62168 3400.9396,1111.3935 3400.22,1111.4 C3400.05356,1111.38784 3399.88644,1111.38784 3399.72,1111.4 C3398.98835,1111.51552 3398.33505,1111.92338 3397.91,1112.53 L3337.12,1198.33 C3334.67844,1201.7945 3335.36264,1206.56199 3338.68,1209.2 C3410,1262.72 3494.38,1289.85 3589.46,1289.85 Z"
      id="Path"
      fill={fill}
    />
    <path
      d="M5628.77,1276 L5709.54,1276 C5713.81078,1275.99448 5717.27,1272.53078 5717.27,1268.26 L5717.27,370.82 C5717.27,366.549218 5713.81078,363.08 5709.54,363.08 L5620.54,363.08 C5602.78766,363.102039 5588.40204,377.487659 5588.38,395.24 L5588.38,685.24 L5575.81,675.65 C5522.52,634.98 5457.36,613.48 5387.39,613.48 C5329.15,613.48 5274.29,627.78 5228.71,654.85 C5124.11,716.71 5061.65,827.68 5061.65,951.69 C5061.65,1075.3 5123.57,1185.69 5227.31,1247.1 C5272.41,1273.9 5333.31,1289.89 5390.14,1289.89 C5461.14,1289.89 5528.45,1267.07 5579.8,1225.65 L5592.54,1215.37 L5592.54,1239.8 C5592.57857,1259.78842 5608.78155,1275.97799 5628.77,1276 L5628.77,1276 Z M5588.37,1074.11 L5586.78,1076.2 C5543.26,1133.61 5476.48,1167.9 5408.17,1167.9 C5286.17,1167.9 5190.54,1072.9 5190.54,951.66 C5190.54,828.38 5281.12,735.41 5401.24,735.41 C5472.72,735.41 5542.05,769.67 5586.72,827.04 L5588.37,829.16 L5588.37,1074.11 Z"
      id="Shape"
      fill={fill}
    />
    <path
      d="M2426.25,1276 L2539.87,1276 C2544.14241,1276 2547.60897,1272.54239 2547.62,1268.27 L2547.62,932.17 C2547.62,762.09 2413.02,618.71 2247.62,612.54 C2173.11,609.88 2112.47,632.05 2067.22,678.77 L2053.78,692.66 L2053.78,659.38 C2053.76346,641.625378 2039.37462,627.236539 2021.62,627.22 L1929.32,627.22 C1925.91469,627.225506 1923.15,629.984686 1923.15,633.39 L1923.15,1268.25 C1923.15,1273.25 1925.92,1275.98 1929.32,1275.98 L2047.6,1275.98 C2051.00922,1275.98 2053.77448,1273.21921 2053.78,1269.81 L2053.78,910.38 C2057.79742,861.086833 2081.3513,815.44444 2119.2,783.61 C2151.77657,756.275568 2192.96475,741.3325 2235.49,741.42 C2241.49,741.42 2247.54,741.71 2253.64,742.29 C2346.1,751.29 2418.55,834.74 2418.55,932.29 L2418.55,1268.22 C2418.53936,1270.27278 2419.34464,1272.24571 2420.78868,1273.70475 C2422.23271,1275.16379 2424.19722,1275.98942 2426.25,1276 L2426.25,1276 Z"
      id="Path"
      fill={fill}
    />
    <path
      d="M3258.2,951.65 C3258.2,758.84 3124.12,613.44 2946.34,613.44 C2763.22,613.44 2630.34,755.68 2630.34,951.65 C2630.34,1144.47 2774.55,1289.87 2965.79,1289.87 C3111.79,1289.87 3193.95,1220.73 3229.95,1179.51 C3232.6312,1176.359 3232.32572,1171.64832 3229.26,1168.87 L3155.53,1099.58 C3154.01332,1098.17603 3151.99924,1097.43508 3149.9343,1097.52143 C3147.86935,1097.60779 3145.92417,1098.5143 3144.53,1100.04 C3109.71,1136.14 3058.08,1177.59 2967.15,1177.59 C2857.15,1177.59 2766.15,1095.47 2755.64,986.59 L2754.82,978.02 L3257,978.02 C3257.87235,969.257862 3258.27293,960.455155 3258.2,951.65 Z M2765.43,869.88 L2768.59,859.74 C2793.59,779.56 2866.66,725.74 2950.51,725.74 C3027.51,725.74 3099.51,782.19 3121.6,859.96 L3124.45,869.96 L2765.43,869.88 Z"
      id="Shape"
      fill={fill}
    />
    <path
      d="M4986.26575,951.65 C4986.26575,758.84 4852.18,613.44 4674.38,613.44 C4491.26,613.44 4358.38,755.68 4358.38,951.65 C4358.38,1144.47 4502.58,1289.87 4693.82,1289.87 C4839.82,1289.87 4921.98,1220.73 4957.97,1179.51 C4960.66165,1176.36603 4960.36478,1171.65149 4957.3,1168.87 L4883.55,1099.58 C4882.03332,1098.17603 4880.01924,1097.43508 4877.9543,1097.52143 C4875.88935,1097.60779 4873.94417,1098.5143 4872.55,1100.04 C4837.73,1136.14 4786.1,1177.59 4695.17,1177.59 C4585.17,1177.59 4494.22,1095.47 4483.67,986.59 L4482.84,978.02 L4985,978.02 C4985.9001,969.259519 4986.32072,960.456391 4986.26575,951.65 L4986.26575,951.65 Z M4493.46,869.88 L4496.62,859.74 C4521.62,779.56 4594.72,725.74 4678.55,725.74 C4755.55,725.74 4827.55,782.19 4849.65,859.96 L4852.49,869.96 L4493.46,869.88 Z"
      id="Shape"
      fill={fill}
    />
    <path
      d="M4320.36,1270.77 L4296.97,1168.77 C4296.192,1164.85506 4292.4633,1162.25159 4288.52,1162.87 C4269.46039,1166.07647 4250.17677,1167.76842 4230.85,1167.93 C4193.4,1167.93 4156.72,1153.75 4139.56,1132.63 C4123.31,1113.63 4119.95,1085.35 4119.95,1048.71 L4119.95,742.32 L4263.28,742.32 C4267.54916,742.32 4271.01,738.859161 4271.01,734.59 L4271.01,635 C4271.01266,632.946411 4270.19805,630.976166 4268.74594,629.524058 C4267.29383,628.07195 4265.32359,627.257342 4263.27,627.26 L4120,627.26 L4120,454.84 C4120,450.559793 4116.53021,447.09 4112.25,447.09 L3998.64,447.09 C3994.4511,447.106478 3991.06096,450.501084 3991.05,454.69 L3991.05,595.08 C3991.05,612.874615 3976.62461,627.3 3958.83,627.3 L3883.89,627.3 C3881.83549,627.297346 3879.86422,628.111636 3878.41053,629.563455 C3876.95683,631.015274 3876.14,632.985491 3876.14,635.04 L3876.14,734.59 C3876.14551,738.856877 3879.60312,742.314491 3883.87,742.32 L3991.05,742.32 L3991.05,1041.74 C3991.05,1118.74 4008.14,1175.08 4043.31,1214.03 C4087.12,1263.65 4150.5,1289.85 4226.68,1289.85 C4256.56,1289.85 4289.3,1286.24 4314.27,1280.18 C4316.32621,1279.74114 4318.12354,1278.5027 4319.26589,1276.73758 C4320.40824,1274.97247 4320.80186,1272.82557 4320.36,1270.77 Z"
      id="Path"
      fill={fill}
    />
  </svg>
);

export const NestedLogoHouseOnly = ({ fill = "#FFFFFF", ...props }) => {
  return (
    <svg {...props} viewBox="0 0 30 34">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-30.000000, -40.000000)" fillRule="nonzero">
          <g transform="translate(30.000000, 40.000000)">
            <path
              d="M28.6050062,10.131586 L16.0607894,0.37054695 C15.4265034,-0.12351565 14.5468841,-0.12351565 13.912598,0.37054695 L1.39210563,10.1313754 C0.514814365,10.8154041 0,11.8778629 0,13.004784 L0,32.2709847 C0,33.2258935 0.758301926,34 1.69371476,34 L28.3062852,34 C29.2416981,34 30,33.2258935 30,32.2709847 L30,13.0066794 C30,11.8786149 29.4839716,10.8152594 28.6050062,10.131586 L28.6050062,10.131586 Z"
              fill="#FF8871"
            ></path>
            <path
              d="M25.7217714,28.8748701 C25.7217714,29.3089407 25.3770719,29.6608244 24.9518636,29.6608244 L20.9071655,29.6608244 C20.4819571,29.6608244 20.1372576,29.3089407 20.1372576,28.8748701 L20.1372576,18.6877899 C20.1372576,15.760468 17.7941136,13.392075 14.917893,13.4301934 C12.0874708,13.46768 9.83612983,15.8762973 9.83612983,18.7661326 L9.83612983,28.8748701 C9.83623942,29.0835006 9.75508776,29.2836153 9.61055626,29.4311196 C9.46602476,29.5786239 9.26997406,29.6612459 9.06560308,29.6612459 L5.02090496,29.6612459 C4.81671271,29.6612459 4.62088363,29.57844 4.4764979,29.4310449 C4.33211217,29.2836499 4.25099711,29.0837393 4.25099711,28.8752913 L4.25099711,18.792668 C4.25099711,12.7253196 9.03713382,7.73181383 14.9808142,7.72886393 C20.9123229,7.72570647 25.7217714,12.6334985 25.7217714,18.6877899 L25.7217714,28.8748701 Z"
              fill={fill}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
