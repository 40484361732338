import styled from "styled-components";
import { ProgressConsumer } from "../../containers/ProgressContext/ProgressContext";

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 1440px;
  height: 5px;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 0.3s ease 0.3s;
  pointer-events: none;
`;

const Background = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.palette.hague30};
`;

const Progress = styled.div.attrs(({ progress }) => ({
  style: {
    width: `calc(${progress} * 100%)`,
  },
}))`
  z-index: 100;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  background-color: ${({ theme }) => theme.palette.hague30};
`;

const ProgressBar = () => (
  <ProgressConsumer>
    {({ animating, progress }) => (
      <Wrapper visible={animating}>
        <Background />
        <Progress progress={progress} />
      </Wrapper>
    )}
  </ProgressConsumer>
);

export { ProgressBar };
